.empty{
  width: 90%;
  height: auto;
  margin: 20px 20px;
  padding: 10px 10px;
  font-size: 1.3rem;
  font-style: italic;
  color: var(--text-color);
  display: flex;
  justify-content: center;
  align-items: center;
}