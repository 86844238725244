.login-background{
  width: 100vw;
	height:100vh;
	background: #092756;
	background: -moz-radial-gradient(0% 100%, ellipse cover, rgba(104,128,138,.4) 10%,rgba(138,114,76,0) 40%),-moz-linear-gradient(top,  rgba(57,173,219,.25) 0%, rgba(42,60,87,.4) 100%), -moz-linear-gradient(-45deg,  #670d10 0%, #092756 100%);
	background: -webkit-radial-gradient(0% 100%, ellipse cover, rgba(104,128,138,.4) 10%,rgba(138,114,76,0) 40%), -webkit-linear-gradient(top,  rgba(57,173,219,.25) 0%,rgba(42,60,87,.4) 100%), -webkit-linear-gradient(-45deg,  #670d10 0%,#092756 100%);
	background: -o-radial-gradient(0% 100%, ellipse cover, rgba(104,128,138,.4) 10%,rgba(138,114,76,0) 40%), -o-linear-gradient(top,  rgba(57,173,219,.25) 0%,rgba(42,60,87,.4) 100%), -o-linear-gradient(-45deg,  #670d10 0%,#092756 100%);
	background: -ms-radial-gradient(0% 100%, ellipse cover, rgba(104,128,138,.4) 10%,rgba(138,114,76,0) 40%), -ms-linear-gradient(top,  rgba(57,173,219,.25) 0%,rgba(42,60,87,.4) 100%), -ms-linear-gradient(-45deg,  #670d10 0%,#092756 100%);
	background: -webkit-radial-gradient(0% 100%, ellipse cover, rgba(104,128,138,.4) 10%,rgba(138,114,76,0) 40%), linear-gradient(to bottom,  rgba(57,173,219,.25) 0%,rgba(42,60,87,.4) 100%), linear-gradient(135deg,  #670d10 0%,#092756 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3E1D6D', endColorstr='#092756',GradientType=1 );

  display: flex;
  justify-content: center;
  align-items: center;
}

.login-card{
  width: 400px;
  height: 300px;

  background-color: rgba(10, 10, 10, 0.415);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.login-icon{
  width: 80px;
  height: 80px;
  position: relative;
  top: -40px;
  /* right:-10px; */
}


.login-input {
    width: 100%;
    height: 30px;
    padding: 16px;

    outline: 0;
    background: #444f77;

    border: 0;
    box-sizing: border-box;
    font-size: 14px;

    color: white;

    border-radius: 5px;
    direction: rtl;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-wrap: wrap;
    line-break: normal;

    margin-top: 20px;

   
  }
.login-input::placeholder{
      color: white;
      opacity: 0.8;
}
.login-input:-webkit-autofill,
.login-input:-webkit-autofill:hover,
.login-input:-webkit-autofill:focus,
.login-input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px #444f77 inset;
    -webkit-box-shadow: 0 0 0 30px #444f77 inset !important;
    -webkit-text-fill-color: white !important;
  }

.login-submit{
border-radius: 10px;
	border: 1px solid #FF4B2B;
	background-color: #FF4B2B;
	color: #FFFFFF;
	font-size: 12px;
	font-weight: bold;
	padding: 14px 75px;
  margin: 30px 0;
	letter-spacing: 1px;
	text-transform: uppercase;
	transition: transform 80ms ease-in;
}