/**
   Animation Keyframes
**/
@keyframes fadeInDown {
   from {
      opacity: 0;
      transform: translateY(-30px);
   }
   to {
      opacity: 1;
      transform: translateY(0px);
   }
}
@keyframes bounce {
   0% {
      opacity: 1;
      transform: translateY(0px);
   }
   50% {
      opacity: 0.7;
      transform: translateY(-20px);
   }
   100% {
      opacity: 1;
      transform: translate(0px);
   }
}

.image-uploader-wrapper {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   padding: 20px;

 
}
   .display-box {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #fbfbfb;
      height: 80px;
      width: 30px;
      padding: 20px;
   }
 .display-box:hover .icon-text-box, .display-box.drag-over .icon-text-box  {
            background-color: #96a599;
            cursor: pointer;
            color: #fff;
            text-shadow: 0px 1px 0 #999;
      }
 .display-box:hover .upload-icon, .display-box.drag-over .upload-icon{
  transform: translateY(-20px);
               animation: bounce 1s ease-in-out infinite;
 }
      .icon-text-box {
         position: relative;
         display: flex;
         flex-direction: column;
         justify-content: center;
         align-items: center;
         border-width: 4px;
         border-style: dashed;
         border-color: #444;
         width: 100%;
         height: 100%;
         text-shadow: 0px 0px 0 #999;
         transition: background-color 0.2s ease-in, color 0.2s ease-in, border-color 0.2s ease-in, text-shadow 0.2s ease-in;

         }
         .upload-icon {
            width: 100px;
            height: 100px;
            margin: 50px;
            box-sizing: border-box;
            transform: translateY(0px);
            transition: transform 0.2s ease-in-out;
         }
            i.fa.fa-upload {
               width: 100px;
               height: 100px;
               font-size: 100px;
            }
         .upload-button, .cancel-upload-button {
            margin: 0 10px;
            position: relative;
            z-index: 9999;
         }
        
         .error-notification {
            animation: fadeInDown 0.5s;
            position: absolute;
            top: 20px;
            background-color: #dfb852;
            border-radius: 5px;
            color: #444;
            padding: 5px 10px;
       
         }
     .error-notification p {
               margin: 0;
               text-shadow: none;
            }
      
      .upload-image-input {
         position: absolute;
         top: 0;
         left: 0;
         width: 100%;
         height: 100%;
         opacity: 0;
         cursor: pointer;
      }
    @media (min-width: 768px) {
      .display-box {
         width: 80px;
      }
   } 