.button-wrapper{
  width: 140px;
  height: 60px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: var(--sidebar-color);
  color:var(--text-color);
  margin: 20px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.button-wrapper div{
  font-size: 1.2rem;
}

.button-icon{
  font-size: 1.6rem;
}