.modal{
  position: absolute;
  top: 40px;
  left: 40px;
  bottom: 40px;
  right: 40px;
  width: calc(100% - 80px);
  height: calc(100vh - 80px);
  border-radius: 5px;
  background-color: var(--body-color);
  color: var(--text-color);
  z-index: 110;
}
.overlay{
 position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
   background-color: var(--overlay-color); 
   z-index: 101;
}

.modal-header{
  width: 95%;
  height: auto;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.icon{
  min-width: 60px;
    border-radius: 6px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
 color: var(--text-color);
}

.closed-modal{
  display: none;
}