.form{
  width: 100%;
  height: 340px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.input{
  border:0; outline:0;
     padding: 1em;
     border-radius:8px;
     display: block;
     width: 70%;
     margin-top: 1em;
     font-family: 'Merriweather', sans-serif;
     box-shadow:0 1px 1px rgba(black, 0.1);
     resize: none;
}
.input:focus{
      box-shadow:0 0px 2px rgba(#e7bdbd, 1)!important;
}

.textarea{
  border:0; outline:0;
     padding: 1em;
     border-radius:8px;
     display: block;
     width: 70%;
     margin-top: 1em;
     font-family: 'Merriweather', sans-serif;
     box-shadow:0 1px 1px rgba(black, 0.1);
     resize: none;
     height: 300px;
}
.textarea:focus{
      box-shadow:0 0px 2px rgba(#e7bdbd, 1)!important;
}
.submit{
  border:0; outline:0;
     padding: 1em;
     border-radius:8px;
     display: block;
     width: 70%;
     margin-top: 1em;
     font-family: 'Merriweather', sans-serif;
     box-shadow:0 1px 1px rgba(black, 0.1);
     resize: none;
     color: white; 
     background: #e74c3c;
     cursor: pointer;
    
   
}
 .submit:hover {
       box-shadow:0 1px 1px 1px rgba(#aaa, 0.6); 
    }