

.fixed_headers {
  width: auto;
  table-layout: fixed;
  border-collapse: collapse;
}
.fixed_headers th, .fixed_headers td {
    padding: 5px;
    text-align: left;
}
  
.fixed_headers td:nth-child(1),.fixed_headers th:nth-child(1) { min-width: 80px; width: auto; padding: 5px 10px; }
.fixed_headers td:nth-child(2),.fixed_headers th:nth-child(2) { min-width: 300px; width:auto; padding: 5px 10px; }
.fixed_headers td:nth-child(3),.fixed_headers th:nth-child(3) { min-width: 200px; width: auto; padding: 5px 10px;}
.fixed_headers td:nth-child(4),.fixed_headers th:nth-child(4) { min-width: 240px; width: auto; padding: 5px 10px;}
.fixed_headers td:nth-child(4),.fixed_headers th:nth-child(4) { min-width: 240px; width: auto; padding: 5px 10px;}
.fixed_headers td:nth-child(5),.fixed_headers th:nth-child(5) { min-width: 80px; width: auto; padding: 5px 10px; text-align: center !important;}
.fixed_headers td:nth-child(6),.fixed_headers th:nth-child(6) { min-width: 80px; width: auto; padding: 5px 10px; text-align: center !important;}

.fixed_headers thead {
    background-color: var(--sidebar-color);
    color: var(--text-color);
}
.fixed_headers thead tr {
      display: block;
      position: relative;
}
.fixed_headers tbody {
    display: block;
    overflow: auto;
    width: auto;
    height: 60vh;  
  color: var(--text-color);
     -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.fixed_headers tbody::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}
.fixed_headers tbody tr:nth-child(even) {
      background-color: var(--list-background);
  color: var(--text-color);
}
.old_ie_wrapper {
  height: 300px;
  width: auto;
  overflow-x: hidden;
  overflow-y: auto; 
   -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.old_ie_wrapper::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}
.old_ie_wrapper tbody { height: auto; }

.list-icon{
 min-width: 60px;
    border-radius: 6px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    cursor: pointer;
}

.info-container{
    width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: auto;
}

.info-row{
    width: 40%;
    min-height: 30px;
    color: var(--text-color);
    display: flex;
    justify-content: space-between;
}
.info-title{
    font-weight: 700;
}