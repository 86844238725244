.card-container{
  width: 350px;
  height: auto;
  min-height: 450px;
  background: var(--sidebar-color);
  border-radius:5px ;
  box-sizing: border-box;
  padding: 0 20px;
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: var(--text-color);
}

.card-header{
  position: relative;
  width: 100%;
  height: 75px;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.card-icon{
  display: block;
  width: 75px;
  height: 75px;
  line-height: 75px;
  text-align: center;
  padding: 0;
  margin: 0;
  background-color: (--primary-color);
  font-size: 1.2rem;
}

.card-title{
  display: block;
  width: auto;
  height: 75px;
  line-height: 75px;
  text-align: center;
  padding: 0 20px;
  margin:0;
}

.card-status{
  display: block;
  position: relative;
  width: auto;
  height: 75px;
  line-height: 75px;
  text-align: center;
  padding: 0 20px;
  margin:0;
}

.card-status:after{
  content: '';
  display: block;
  position: relative;
  width: 15px;
  height: 15px;
  border-radius: 15px;
  background: var(--sidebar-color);
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
}

.card-status-active:after{
  background: red;
}